import { Controller } from '@hotwired/stimulus';

import '../../styles/facebookpage/one_page.scss';

import { Events, FileUploadWithPreview, ImageAddedEvent, ImageDeletedEvent } from "file-upload-with-preview";

export default class extends Controller {
    static targets = [ "service", "headerPictureImage", "aboutPictureOneImage", "aboutPictureTwoImage", "locationPictureImage" ];
    static values = { header: String, aboutOne: String, aboutTwo: String, location: String };
        
    connect(){
        if(this.headerValue != ''){
            var firstUpload = new FileUploadWithPreview("headerPictureImage", {
                images: {
                    baseImage: this.headerValue,
                }  
            });
        }else{
            var firstUpload = new FileUploadWithPreview("headerPictureImage", {});
        } 
        
        if(this.aboutoneValue != ''){
            var firstUpload = new FileUploadWithPreview("aboutPictureOneImage", {
                images: {
                    baseImage: this.aboutOneValue,
                }  
            });
        }else{
            var firstUpload = new FileUploadWithPreview("aboutPictureOneImage", {});
        } 
        
        if(this.abouttwoValue != ''){
            var firstUpload = new FileUploadWithPreview("aboutPictureTwoImage", {
                images: {
                    baseImage: this.aboutTwoValue, 
                }  
            });
        }else{
            var firstUpload = new FileUploadWithPreview("aboutPictureTwoImage", {});
        } 
        
        if(this.locationValue != ''){
            var firstUpload = new FileUploadWithPreview("locationPictureImage", {
                images: {
                    baseImage: this.locationValue,
                }  
            });
        }else{
            var firstUpload = new FileUploadWithPreview("locationPictureImage", {});
        } 
        
        window.addEventListener(Events.IMAGE_ADDED, this.handleImageAdded.bind(this));
        window.addEventListener(Events.IMAGE_DELETED, this.handleImageDeleted.bind(this));
        
        
        var $collectionHolder;

        var $addTagButton = $('<td></td><td></td><td></td><td><button type="button" class="add_tag_link btn btn-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"/><line x1="5" y1="12" x2="19" y2="12"/></svg></button></td>');
        var $newLinkTr = $('<tr></tr>').append($addTagButton);

        $collectionHolder = $(this.serviceTarget);
    
        $collectionHolder.append($newLinkTr);
        $collectionHolder.data('index', $collectionHolder.find('input').length);

        $addTagButton.on('click', function(e) {
            addTagForm($collectionHolder, $newLinkTr);
        });

        $collectionHolder.on('click', '.remove-service', function(e) {
            e.preventDefault();
            $(this).closest('.service-item')
                .fadeOut()
                .remove();
        });
        
        function addTagForm($collectionHolder, $newLinkTr) {
            // Get the data-prototype explained earlier
            var prototype = $collectionHolder.data('prototype');

            // get the new index
            var index = $collectionHolder.data('index');

            var newForm = prototype;
            newForm = newForm.replace(/__name__/g, index);

            $collectionHolder.data('index', index + 1);

            var $newFormTr = $('<tr class="service-item"></tr>').append(newForm);

            $newLinkTr.before($newFormTr);
        }
    };
    
    handleImageAdded(event) {
        const detail = event.detail;
        const uploadId = detail.uploadId; // par exemple "headerPictureImage"
        const file = detail.cachedFileArray[0]; // Prendre le premier fichier

        // Déclarer inputTarget ici pour assurer sa disponibilité dans tout le bloc de fonction
        let inputTarget; 

        if (uploadId === 'headerPictureImage') {
            inputTarget = this.headerPictureImageTarget;
        }
        if (uploadId === 'aboutPictureOneImage') {
            inputTarget = this.aboutPictureOneImageTarget;
        }
        if (uploadId === 'aboutPictureTwoImage') {
            inputTarget = this.aboutPictureTwoImageTarget;
        }
        if (uploadId === 'locationPictureImage') {
            inputTarget = this.locationPictureImageTarget;
        }
        // Assurez-vous que inputTarget a été défini avant de continuer
        if (inputTarget) {
            console.log("Cible trouvée pour :", uploadId);
            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(file);
            console.log("DataTransfer après ajout :", dataTransfer); // Vérifiez le contenu de DataTransfer
            inputTarget.files = dataTransfer.files;
            console.log("Fichiers après affectation :", inputTarget.files); // Vérifiez que l'input contient le bon fichier
        } else {
            console.warn(`Cible pour ${uploadId} non trouvée.`);
        }
        
    }
    
    handleImageDeleted(event) {
        const detail = event.detail;
        const uploadId = detail.uploadId;

        let inputTarget;

        if (uploadId === 'headerPictureImage') {
            inputTarget = this.headerPictureImageTarget;
        }
        if (uploadId === 'aboutPictureOneImage') {
            inputTarget = this.aboutPictureOneImageTarget;
        }
        if (uploadId === 'aboutPictureTwoImage') {
            inputTarget = this.aboutPictureTwoImageTarget;
        }
        if (uploadId === 'locationPictureImage') {
            inputTarget = this.locationPictureImageTarget;
        }

        if (inputTarget) {
            // Créer un nouveau DataTransfer vide pour réinitialiser les fichiers de l'input
            const dataTransfer = new DataTransfer();
            inputTarget.files = dataTransfer.files;
        }
    }
    
    validateForm(event) {
        // Liste des IDs de vos containers d'upload
        const uploadIds = ['headerPictureImage', 'aboutPictureOneImage', 'aboutPictureTwoImage'];

        let isValid = true;

        uploadIds.forEach(uploadId => {
          // Récupération de l'élément de container d'upload par son ID
          const containerElement = document.querySelector(`[data-upload-id="${uploadId}"]`);

          // Vérification si l'image existante ou une nouvelle image est présente
          if (!this.hasImage(containerElement)) {
            isValid = false;
            // Afficher un message d'erreur ou ajouter une classe CSS d'erreur selon votre UI
            console.error(`Une image est requise pour ${uploadId}.`);
          }
        });

        // Empêcher la soumission du formulaire si les conditions ne sont pas remplies
        if (!isValid) {
          event.preventDefault();
          // Vous pouvez ici afficher un message général d'erreur ou effectuer toute action UI nécessaire
          alert("Veuillez sélectionner une image pour chaque champ requis.");
        }
      }

      // Méthode pour vérifier si un container contient une image existante ou sélectionnée
      hasImage(containerElement) {
        // Vérifiez ici la logique spécifique à votre implémentation
        // Par exemple, vérifier si containerElement contient un élément <img> ou si l'input de fichier caché associé a une valeur
        return containerElement.querySelector('img') !== null || containerElement.querySelector('input[type="file"]').files.length > 0;
      }


    disconnect() {
        window.removeEventListener(Events.IMAGE_ADDED, this.handleImageAdded.bind(this));
    }
}