import { Controller } from '@hotwired/stimulus';

import '../styles/profile.scss';


export default class extends Controller {
    static targets = [ "jsEditProfileFormWrapper", "jsPasswordProfileFormWrapper" ]
    
    connect() {
        
    }
    
    editForm(e){
        e.preventDefault();
        const jsEditProfileFormWrapper = this.jsEditProfileFormWrapperTarget;
        
        const $form = $(e.currentTarget);
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(data) {
                window.location.reload();
            },
            error: function(data) {
                jsEditProfileFormWrapper.innerHTML = data.responseText;
                var newForm = $(jsEditProfileFormWrapper).find( 'form' ).addClass('was-validated');
            }
        });
    }
    
    passwordForm(e){
        e.preventDefault();
        const jsPasswordProfileFormWrapper = this.jsPasswordProfileFormWrapperTarget;
        
        var $form = $(e.currentTarget);
        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: function(data) {
                window.location.reload();
            },
            error: function(data) {
                jsPasswordProfileFormWrapper.innerHTML = data.responseText;
                var newForm = $(jsPasswordProfileFormWrapper).find( 'form' ).addClass('was-validated');
            }
        });
    }
}
