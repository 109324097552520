import { Controller } from '@hotwired/stimulus';

import '../../styles/campaign_bundle/form.scss';

export default class extends Controller {
    static targets = ["collectionContainer", "destinationTypeContainer", "optimizationGoalContainer"]
    
    static values = { updateUrl: String }
    
    connect() { 
        const collectionContainer = this.collectionContainerTarget;
        
        if(collectionContainer.dataset.index === 0){
            this.addFormToCollection();
        }
    }
    
    addFormToCollection(e) {
        const collectionContainer = this.collectionContainerTarget;
        const formIndex = collectionContainer.dataset.index

        const item = document.createElement('div');
        item.classList.add('col-md-6', 'item');
        item.setAttribute('data-index', formIndex);

        // Remplacez __name__ par newIndex dans le prototype
        item.innerHTML = collectionContainer.dataset.prototype.replace(/__name__/g, formIndex);

        collectionContainer.appendChild(item);

        collectionContainer.dataset.index = formIndex + 1; // Incrémentez l'index pour le prochain élément
    }
    
    deleteItem(e){
        const item = e.currentTarget.closest('.item');
        
        item.remove();
    }
    
    onCampaignObjectiveChange(event) {
        const itemElement = event.currentTarget.closest('.item');
        const campaignObjectiveValue = event.currentTarget.value;
        const destinationTypeValue = this.getDestinationTypeValue(itemElement);

        const data = {
            campaignObjective: campaignObjectiveValue,
            destinationType: destinationTypeValue
        };

        this.handleFormChange(itemElement, data);
    }

    onDestinationTypeChange(event) {
        const itemElement = event.currentTarget.closest('.item');
        const campaignObjectiveValue = this.getCampaignObjectiveValue(itemElement);
        const destinationTypeValue = event.currentTarget.value;

        const data = {
            campaignObjective: campaignObjectiveValue,
            destinationType: destinationTypeValue
        };

        this.handleFormChange(itemElement, data);
    }
    
    handleFormChange(itemElement, data) {
        fetch(this.updateUrlValue, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            this.updateFields(data, itemElement);
        })
        .catch(error => console.error('Error:', error));
    }

    getDestinationTypeValue(itemElement) {
        // Trouvez le champ destinationType dans l'élément itemElement
        // Assurez-vous que le sélecteur correspond à la structure de votre formulaire
        const destinationTypeFields = itemElement.querySelectorAll('[name*="[destinationType]"]');

        // Parcourez tous les champs pour trouver celui qui est coché
        for (const field of destinationTypeFields) {
            if ((field.type === 'checkbox' || field.type === 'radio') && field.checked) {
                return field.value;
            }
        }

        // Si aucun champ n'est coché, renvoyez null
        return null;
    }

    getCampaignObjectiveValue(itemElement) {
        // Trouvez le champ campaignObjective dans l'élément itemElement
        // Assurez-vous que le sélecteur correspond à la structure de votre formulaire
        const campaignObjectiveField = itemElement.querySelector('[name*="[campaignObjective]"]:checked');

        // Vérifiez si le champ a été trouvé et renvoyez sa valeur
        return campaignObjectiveField ? campaignObjectiveField.value : null;
    }

    updateFields(data, itemElement) {
        const formIndex = itemElement.getAttribute('data-index');

        const destinationTypeContainer = itemElement.querySelector('[data-target="destinationTypeContainer"]');
        const optimizationGoalContainer = itemElement.querySelector('[data-target="optimizationGoalContainer"]');

        if (destinationTypeContainer && data.destinationType) {
            destinationTypeContainer.innerHTML = data.destinationType;

            // Mise à jour des attributs pour les boutons radio de destinationType
            const destinationTypeRadios = destinationTypeContainer.querySelectorAll('input[type="radio"]');
            destinationTypeRadios.forEach((radio, index) => {
                radio.id = `campaign_bundle_campaignSettings_${formIndex}_destinationType_${index}`;
                radio.name = `campaign_bundle[campaignSettings][${formIndex}][destinationType]`;
            });
        }

        if (optimizationGoalContainer && data.optimizationGoal) {
            optimizationGoalContainer.innerHTML = data.optimizationGoal;

            // Mise à jour des attributs pour le select d'optimizationGoal
            const optimizationGoalSelect = optimizationGoalContainer.querySelector('select');
            optimizationGoalSelect.id = `campaign_bundle_campaignSettings_${formIndex}_optimizationGoal`;
            optimizationGoalSelect.name = `campaign_bundle[campaignSettings][${formIndex}][optimizationGoal]`;
        }
    }
}