import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        
        window.fbAsyncInit = function () {
            FB.init({
                appId: '1911769145733061',
                cookie: true,
                xfbml: true,
                version: '16'
            });

        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    statusChangeCallback(response) {
        if (response.status === 'connected') {
            // Logged into your app and Facebook.
            console.log('Welcome! Fetching your information.... ');
        } else {
            // The person is not logged into your app or we are unable to tell.
            console.log('Please log into this app.');
        }
    }
    
    checkLoginState() {   
        FB.getLoginStatus(function(response) {   // See the onlogin handler
          statusChangeCallback(response);
        });
    }
}



